
/* =============== STYLES =============== */

/* Basic */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: @basic-font-size;
}

body {	
	position: relative;
	font-family: @proxima;
	color: @black;
	overflow-x: hidden;
	//overflow-y: hidden;
	//max-height: 984px;
	
	@media @max-md {
		overflow-x: hidden;
		overflow-y: auto;
	}
	
	&:after {
		.transition;
		content: "";		
		background: url('../dist/images/bg.svg') no-repeat center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		opacity: 0;
		height: 0;
		width: auto;
		z-index: -1;
		
		@media @max-md {
			height: 100%;
		}
		
		
	}
	
	&.loaded:after {
		height: 200%;
		opacity: .2;
		overflow: hidden;
	}
}

/* Important */
.container {
	max-width: 600px;
	margin: auto;
	
	@media @max-md {
		width: 100%;
	}
	@media (min-width: 1600px) {
		max-width: 100%;
	}
}

/* Text */
.page-title {
	font-size: 2rem;
	line-height: 1;
	margin: 2rem 0;
	font-weight: 400;
	
	&.result {
		color: @gray;
	}
}

.action-title {
	font-size: 1.5rem;
	line-height: 1;
	margin: 3rem 0 1rem 0;
	font-weight: 400;
	text-align: center;
	
	&.waiting {
		&:after {
			content: " ...";
			position: absolute;
			margin-left: 5px;
			-webkit-animation: waitDots 2.5s infinite linear;
			animation: waitDots 2.5s infinite linear;
		}
		
		.waiting-1, .waiting-2, .waiting-3 {
			.transition;
			display: none;
			transform: scale(0);
			&.active {
				display: inline-block;
				transform: scale(1);
			}
		}
	}
	
	&.queue-info {
		.transition;
		visibility: hidden;
		height: 0;
		&.active {
			visibility: visible;
			height: auto;
		}
	}
}

.action-text {
	font-size: 1.2rem;
	font-weight: 400;
	padding: 1rem 0;
}

.confirmation-id-result-container {
	.transition;
	visibility: hidden;
	width: 0;
	height: 0;
	opacity: 0;
	transform: scale(0);
	
	&.active {
		visibility: visible;
		width: auto;
		height: auto;
		opacity: 1;
		transform: scale(1);
	}
}

.confirmation-id-result {
	&__title {
		font-size: 2rem;
		//font-weight: 600;
		margin: 2rem 0;
	}
	&__digits {
		width: 100%;
		font-size: 1.5rem;
		line-height: 1.5;
		//font-weight: 600;
		text-align: center;
		margin: 0 auto 2rem 0;;
		
		@media @max-sm {
			font-size: 1.7rem;
		}
	}
}

/* Input */
.input {
	display: block;
	background: @white;
	padding: .8rem 1.8rem .8rem 1rem;
	color: @gray-light;
	border: 1px solid @gray;
	margin: 1rem auto;
	font-size: 1rem;
	&::placeholder {
		color: @gray-light;
	}
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  	color: @gray-light;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  	color: @gray-light;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  	color: @gray-light;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  	color: @gray-light;
	}
	
}


#digits {
	width: 100%;
	padding: 0 1rem;
}

.digits-input-align {
	float: left;
	width: 33.333333%;
	padding: .5rem;
	
	@media @max-sm {
		padding: .2rem;
	}
}

.digits-input {
	position: relative;
	display: inline-block;
	background: @white;	
	color: @black;
	width: 100%;
	padding: .8rem 1rem;	
	font-size: 1.3rem;
	font-weight: 600;
	border: 1px solid @gray;
	text-align: center;
	
	&.success {
		color: @gray-light;
	}
	
	&.result {
		color: @gray;
	}
		
	&.not-filled {
		border: 1px solid @red;
		-webkit-animation: shakeInput .5s 1 linear;
		animation: shakeInput .5s 1 linear;
	}
	
	@media @max-sm {
		font-size: .9rem;
	}
}

/* Login */
.login-input {
	&.not-filled {
		border: 1px solid @red;
		-webkit-animation: shakeInput .5s 1 linear;
		animation: shakeInput .5s 1 linear;
		
	}
}

.login-warning {
	.transition;
	position: absolute;
	left: 0;
	right: 0;
	display: none;
	visibility: hidden;
	opacity: 0;
	height: 0;
	width: 0;
	color: @red;
	font-size: 1.3rem;
	font-weight: 400;
	margin: 0 auto 2rem auto;
	transform: scale(0);
	
	&.active {
		display: block;
		visibility: visible;
		height: auto;
		width: auto;
		opacity: 1;
		transform: scale(1);
	}
}

/* Button */
.btn--prim {
	.transition;
	display: table;
	background: @col-prim;
	color: @white;
	padding: 1rem 4rem;
	border-radius: @radius;
	margin: 2rem auto 0 auto;
	text-decoration: none;
	border: 2px solid transparent;
	font-size: 16px;
	font-weight: 600;
	font-family: proxima nova, sans-serif;
	cursor: pointer;
	
	&:hover {
		background: @col-prim * 1.1;
	}
	
	&:active {
		background: @col-prim / 1.1;
	}
	
	&.blue {
		background: @col-sec;
		&:hover {
			background: @col-sec * 1.1;
		}

		&:active {
			background: @col-sec / 1.1;
		}
	}
	
	&.waiting {
		background: @gray-lightest;
		color: @black;
		border: 2px solid @gray-dark;
		padding: 1rem 3.5rem 1rem 3rem;
		cursor: default;
		.waiting-text {
			&:after {
				content: " ...";
				position: absolute;
				margin-left: 5px;
				-webkit-animation: waitDots 2.5s infinite linear;
				animation: waitDots 2.5s infinite linear;
			}
		}
	}
	
	&.success {
		display: none;
		background: @white;
		color: @col-prim;
		font-weight: 600;
		border: 2px solid @col-prim;		
		padding: .5rem 3rem .1rem 3rem;
		cursor: default;
		.material-icons {
			
		}
		&.active {
			display: inline-block;
		}
	}
	
	&.validating {
		background: @gray-lightest;
		color: @black;
		border: 2px solid @gray-dark;
		padding: 1rem 3.5rem 1rem 3rem;
		cursor: default;
		.validating-text {	
			&:after {
				content: " ...";
				position: absolute;
				margin-left: 5px;
				z-index: 0;
				-webkit-animation: waitDots 2.5s infinite linear;
				animation: waitDots 2.5s infinite linear;
			}
		}
	}
	
	&.validating-green {
		position: absolute;
		left: 0;
		background: @col-prim;
		color: @white;
		border: 2px solid transparent;
		padding: 1rem 3.5rem 1rem 3rem;
		cursor: default;
		.validating-text {	
			&:after {
				content: " ...";
				position: absolute;
				margin-left: 5px;
				z-index: 2;
				-webkit-animation: waitDots 2.5s infinite linear;
				animation: waitDots 2.5s infinite linear;
			}
		}
	}
	
	&:after {
		.clear-after;
	}
}

.green-btn-progress {
	position: absolute;
	width: 0%;
	height: 63px;
	overflow: hidden;
}

.validating-btns {
	display: inline-block;
	position: relative;
}

.btn-success {
	&__title {
		display: inline-block;
		height: 24px;
		line-height: 24px;
		vertical-align: top;
	}
}



/* Digits choose */
.digits-choose {
	.transition;
	position: relative;
	display: inline-block;
	background: @col-prim;
	color: @white;
	width: 100px;
	height: 100px;
	margin: 1rem;
	text-decoration: none;
	
	&__title {
		font-size: 3rem;		
		height: 100px;
		line-height: 100px;		
		vertical-align: middle;
	}
	
	&__subtitle {
		position: absolute;
		bottom: 1rem;
		left: 0;
		right: 0;
		margin: auto;
		font-size: 1rem;
	}
	
	&:hover {
		background: @col-prim * 1.1;
	}
	
	&:active {
		background: @col-prim / 1.1;
	}
}


.example-img {
	width: 50%;
	height: auto;
	margin: 1rem auto;
	
	@media @max-sm {
		width: 100%;
	}
}


/* WARNINGS */ 



.small-warning, .small-warning-numbers {
	.transition;
	display: none;
	visibility: hidden;
	opacity: 0;
	height: 0;
	width: 0;
	color: @red;
	font-weight: 400;
	margin: 0 auto .5rem auto;
	transform: scale(0);
	
	&.active {
		display: block;
		visibility: visible;
		height: auto;
		width: auto;
		opacity: 1;
		transform: scale(1);
	}
}



/* HELPERS */
.margin-0 {
	margin: 0;
}

.margin-top-bot-1 {
	margin: 1rem 0;
}

.bold-text {
	font-weight: 600;
}

.hidden {
	display: none;
}


/* ERROR */
.error-symbol {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 10px solid @col-thr;
	margin: auto;
	
	&:after {
		content: "!";
		color: @col-thr;
		font-size: 8rem;
		font-weight: 600;
	}
}


/* LOAD SCREEN */
.load-screen {
	.transition;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	display: flex;
	align-items: center;
	align-self: center;
	width: 100vw;
	height: 100vh;
	visibility: hidden;
	background: rgba(255,255,255,0);
	opacity: 0;
	z-index: 2;
	
	&.active {		
		visibility: visible;
		background: rgba(255,255,255,.95);
		opacity: 1;
	}
	
	&__content {
		margin: auto;
	}
	
	&__head {
		display: none;
		
		&.active {
			display: block;
		}
	}

	&__error, &__help {
		display: none;

		.help-symbol {
			&:before {
				content: "?";
				display: inline-block;
				color: @col-sec;
				font-size: 9rem;
				height: 10rem;
				width: 10rem;
				border: 10px solid @col-sec;
				border-radius: 50%;				
			}
		}
		
		&.active {
			display: block;
		}
	}

	&__queue {
		.transition;
		visibility: hidden;
		opacity: 0;
		transform: scale(0);
		height: 0;
		&.active {
			visibility: visible;
			opacity: 1;
			transform: scale(1);
			height: auto;	
		}
	}
	
	&__validating {
		.transition;
		visibility: hidden;
		opacity: 0;
		transform: scale(0);
		height: 0;
		
		.validating-btns {
			display: none;
		}
		
		&.active {
			visibility: visible;
			opacity: 1;
			transform: scale(1);
			height: auto;
			
			.validating-btns {
				display: inline-block;
			}
		}
	}
}

.ask-for-help, .dont-want-help {
	display: inline-block;

	&.sent {
		display: none;
	}
}

.help {
	&-success, &-failed {
		display: none;
		padding: 1rem 0;
		font-size: 1.25rem;
		font-weight: 700;

		&.active {
			display: block;
		}
	}
}

.action-how-id {
	transition: background-color .2s linear;
	display: inline-block;
	background-color: @col-prim;
	color: @white;
	text-decoration: none;
	font-size: 1.25rem;
	padding: 1rem 2rem;

	&:hover {
		background-color: @col-prim * 1.1;
	}

	&:active {
		background-color: @col-prim / 1.1;
	}
}

/* CLEAR */
.clear {
	width: 100%;
	height: 1px;
	clear: both;
}

.clear-after {
	content: "";
	display: table;
	clear: both;
}


/* TRANSITION */
.transition {
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

/* STYLEGUIDE */
.styleguide-textarea {
	width: 100%;
	height: 50px;
	overflow: auto;
}

/* PRELOADER */
.preloader-dot {
	position: absolute;
	top: 100px;
	left: 100px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: @col-prim;
  	animation: rotate 1s infinite linear, changeDotColor 2s infinite linear;
}

.preloader-dot2 {
	position: absolute;
	top: 100px;
	left: 295px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: @col-sec;
  	animation: rotateInvert 1s infinite linear, changeDotColor2 2s infinite linear;
}


.preloader-circle-align {
	height: 52px;
	width: 52px;
	margin: 4rem auto;	
}

/*
.preloader-circle-container {
	width: 40px;
	height: 40px;
	margin: auto;
	transform: rotate(0deg);
	animation: infiniteRotatingElement 15s infinite linear;
	.preloader-circle {
		position: relative;
		background: @col-prim;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		margin: auto;
		transform: scale(1);
		animation: sizingMainCircle 1.25s infinite ease-in-out, changeCirclesColor 2.5s infinite linear;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			height: 25px;
			width: 25px;
			border-radius: 50%;
			background: @col-prim;
			transform: translateY(0px);
			animation: movingUpDownCircle 2.5s infinite ease-in-out, changeCirclesColor 2.5s infinite linear;
		}
	}
}
*/

.preloader-circle-container {
    width: 40px;
   height: 40px;
   margin: auto;
   transform: rotate(0deg);
   animation: infiniteRotatingElement 10s infinite linear;
   
   .preloader-circle {
       position: relative;
       background: @col-prim;
       width: 40px;
       height: 40px;
       border-radius: 50%;
       margin: auto;
       transform: scale(1);
       animation: sizingMainCircle 1.5s infinite ease-in-out, changeCirclesColor 3s infinite linear;
       &:after {
           content: "";
           position: absolute;
           top: 0;
           right: 0;
           bottom: 0;
           left: 0;
           margin: auto;
           height: 25px;
           width: 25px;
           border-radius: 50%;
           background: @col-prim;
           transform: translateY(0px);
           animation: movingUpDownCircle 3s infinite ease-in-out, changeCirclesColor 3s infinite linear;
       }
   }
}

/* KEYFRAMES */
@keyframes shakeInput {
	0% {left:0px;}
	20% {left:6px;}
	40% {left:-6px;}
	60% {left:6px;}
	80% {left:-6px;}
	100% {left:0px;}
}

@-webkit-keyframes shakeInput {
	0% {left:0px;}
	20% {left:6px;}
	40% {left:-6px;}
	60% {left:6px;}
	80% {left:-6px;}
	100% {left:0px;}
}

@keyframes sizingMainCircle {
   0% {transform: scale(1.15);}
   12% {transform: scale(1.15);}
   32% {transform: scale(1);}
   72% {transform: scale(1);}
   88% {transform: scale(1.15);}
   100% {transform: scale(1.15);}
}

@keyframes movingUpDownCircle {
   0% {transform: translateY(0px);}
   20% {transform: translateY(-60px);}
   50% {transform: translateY(0px);}
   70% {transform: translateY(60px);}
   100% {transform: translateY(0px);}
}

@keyframes infiniteRotatingElement {
   0% {transform: rotate(0deg);}
   100% {transform: rotate(360deg);}
}

@keyframes changeCirclesColor {
   0% {background: @col-prim}
   40% {background: @col-prim}
   50% {background: @col-sec}
   90% {background: @col-sec}
   100% {background: @col-prim}
}

@-webkit-keyframes sizingMainCircle {
   0% {transform: scale(1.15);}
   12% {transform: scale(1.15);}
   32% {transform: scale(1);}
   72% {transform: scale(1);}
   88% {transform: scale(1.15);}
   100% {transform: scale(1.15);}
}

@-webkit-keyframes movingUpDownCircle {
   0% {transform: translateY(0px);}
   20% {transform: translateY(-60px);}
   50% {transform: translateY(0px);}
   70% {transform: translateY(60px);}
   100% {transform: translateY(0px);}
}

@-webkit-keyframes infiniteRotatingElement {
   0% {transform: rotate(0deg);}
   100% {transform: rotate(360deg);}
}

@-webkit-keyframes changeCirclesColor {
   0% {background: @col-prim}
   40% {background: @col-prim}
   50% {background: @col-sec}
   90% {background: @col-sec}
   100% {background: @col-prim}
}
/*
@keyframes sizingMainCircle {
	0% {transform: scale(1);}	
	60% {transform: scale(1);}
	80% {transform: scale(1.2);}
	100% {transform: scale(1);}
}

@keyframes movingUpDownCircle {
	0% {transform: translateY(0px);}
	25% {transform: translateY(-50px);}
	50% {transform: translateY(0px);}
	75% {transform: translateY(50px);}
	100% {transform: translateY(0px);}
}

@keyframes infiniteRotatingElement {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}

@keyframes changeCirclesColor {
	0% {background: @col-prim}
	40% {background: @col-prim}
	50% {background: @col-sec}
	90% {background: @col-sec}
	100% {background: @col-prim}
}

@-webkit-keyframes sizingMainCircle {
	0% {transform: scale(1);}	
	60% {transform: scale(1);}
	80% {transform: scale(1.2);}
	100% {transform: scale(1);}
}

@-webkit-keyframes movingUpDownCircle {
	0% {transform: translateY(0px);}
	25% {transform: translateY(-50px);}
	50% {transform: translateY(0px);}
	75% {transform: translateY(50px);}
	100% {transform: translateY(0px);}
}

@-webkit-keyframes infiniteRotatingElement {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}

@-webkit-keyframes changeCirclesColor {
	0% {background: @col-prim}
	40% {background: @col-prim}
	50% {background: @col-sec}
	90% {background: @col-sec}
	100% {background: @col-prim}
}
*/


@keyframes changeDotColor {
	0% {
		background: @col-prim;
	}
	
	24% {
		background: @col-prim;
	}
	
	25% {
		background: @col-sec;
	}
	
	49% {
		background: @col-sec;
	}
	
	50% {
		background: @col-sec;
	}
	
	74% {
		background: @col-sec;
	}
	
	75% {
		background: @col-prim;
	}
	
	99% {
		background: @col-prim;
	}
	
	100% {
		background: @col-prim;
	}
}

@keyframes changeDotColor2 {
	0% {
		background: @col-sec;
	}
	
	24% {
		background: @col-sec;
	}
	
	25% {
		background: @col-prim;
	}
	
	49% {
		background: @col-prim;
	}
	
	50% {
		background: @col-prim;
	}
	
	74% {
		background: @col-prim;
	}
	
	75% {
		background: @col-sec;
	}
	
	99% {
		background: @col-sec;
	}
	
	100% {
		background: @col-sec;
	}
}

@keyframes rotate {
	0% {
		transform: 	rotate(0deg)
					translate(-100px)
					rotate(0deg);				
	}
	
	100% {
		transform:  rotate(360deg)
					translate(-100px)
					rotate(-360deg);
	}

}

@keyframes rotateInvert {
	0% {
		transform: 	rotate(0deg)
					translate(100px)
					rotate(0deg);
	}
	100% {
		transform:  rotate(-360deg)
					translate(100px)
					rotate(360deg);
	}

}


@-webkit-keyframes waitDots {
	0% {
		content: "";
	}
	
	33% {
		content: " .";
	}
	
	66% {
		content: " ..";
	}
	
	100% {
		content: " ...";
	}
}

@keyframes waitDots {
	0% {
		content: "";
	}
	
	33% {
		content: " .";
	}
	
	66% {
		content: " ..";
	}
	
	100% {
		content: " ...";
	}
}




