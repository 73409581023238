body {
    &.page-login {
        .lang-select {
            justify-content: flex-end;
        }
        .page-content {
            display: flex;
            height: calc(~"100vh - 51px");
            align-content: center !important;
        }
        h1 {
            width: 100%;
        }
        form {
            margin: 0 auto;
            input:first-of-type {
                margin-top: 0;
            }
        }
    }
    &.download-page {
        .container {
            max-width: 580px;
        }
        .download {
            p {
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
            }
            .btn {
                padding-left: 3rem;
                padding-right: 3rem;
            }
            input {
                display: block;
                max-width: 350px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1249px) {
        background-image: url('/uploads/bg-mobile.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media (min-width: 1250px) {
        background-image: url('/uploads/bg.svg');
        background-position: 0 -11px;
        background-size: initial;
        &.page-login .page-content {
            max-width: 100%;
            padding-top: 100px;
            align-content: flex-start;
            h1 {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.lang-select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 15px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 1;
    &__order-number {
        font-size: 14px;
        color: #999;
    }
    &__wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        height: 30px;
        transition: all .2s;
        &.is-open {
            .lang-select__items {
                padding: 5px 10px;
                border-radius: @radius;
                &::before {
                    position: absolute;
                    left: -1px;
                    bottom: -1px;
                    display: block;
                    height: calc(~"100% - 39px");
                    width: calc(~"100% + 2px");
                    border-bottom-left-radius: @radius;
                    border-bottom-right-radius: @radius;
                    background-color: #f3f3f3;
                    content: "";
                }
                &::after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    height: calc(~"100% - 41px");
                    width: 100%;
                    background-color: #fff;
                    content: "";
                }
            }
            .lang-select__item {
                position: relative;
                display: flex;
                pointer-events: all;
                z-index: 1;
                &.is-active {
                    padding-top: 0;
                    padding-bottom: 20px;
                    order: 1;
                    pointer-events: none;
                }
            }
        }
    }
    &__items {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        width: 120px;
        padding: 0 10px;
        transition: all .2s;
    }
    &__item {
        display: none;
        justify-content: flex-end;
        width: 100%;
        padding: 5px 0;
        text-decoration: none;
        color: @black;
        pointer-events: none;
        order: 2;
        transition: all .2s;
        &--title {
            margin-right: 5px;
            font-weight: 700;
            font-size: 15px;
        }
        &--icon {
            width: 20px;
            height: 20px;
        }
        &.is-active {
            display: flex;
            order: 1;
            pointer-events: none;
            .lang-select__item--title {
                font-weight: 700;
            }
        }
        &:hover,
        &:focus,
        &:active {
            color: @col-sec;
        }
    }
    @media (min-width: 1250px) {
        justify-items: flex-end;
        border-bottom: 0;
        &__order-number {
            display: none;
        }
        &__wrapper {
            width: 100%;
        }
        &__items {
            width: 100%;
            justify-content: flex-end;
            background-color: transparent;
        }
        &__item {
            display: flex;
            width: auto;
            margin-left: 20px;
            pointer-events: all;
            order: initial;
            &:not(.is-active) {
                opacity: .5;
            }
            &.is-active {
                order: initial;
            }
            &:hover,
            &:focus,
            &:active {
                opacity: 1;
            }
        }
    }
}

.page-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    padding: 20px 15px;
    margin: 0 auto;
    @media (min-width: 1250px) {
        display: block;
        max-width: 100%;
        padding-top: 100px;
        h1 {
            width: 100%;
            text-align: center;
        }
    }
}

.form {
    width: 100%;
    &__label {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        text-align: center;
        color: #999;
    }
    &__row {
        &.js-radio-row {
            .form__widget {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
            margin-bottom: 10px;
            input {
                position: absolute;
                visibility: hidden;
                opacity: 0;
                &:checked + label {
                    border-color: @col-sec;
                    color: @col-sec;
                    &::before {
                        border-color: @col-sec;
                    }
                    &::after {
                        opacity: 1;
                    }
                }
                &[disabled] + label {
                    opacity: .5;
                }
            }
            label {
                position: relative;
                display: flex;
                align-items: center;
                padding: 9px 10px;
                border-radius: @radius;
                border: 1px solid @black;
                margin: 0 3px;
                font-size: 16px;
                transition: all .2s;
                cursor: pointer;
                &::before {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    border: 1px solid @black;
                    border-radius: 50%;
                    margin-right: 5px;
                    transition: all .2s;
                    content: "";
                }
                &::after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 13px;
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin: auto 0;
                    background-color: @col-sec;
                    opacity: 0;
                    transition: all .2s;
                    content: "";
                }
            }
            .form__label {
                &::after {
                    display: inline-block;
                    margin-left: 5px;
                    content: "*";
                    color: @col-sec;
                }
            }
        }
        .select2 {
            width: 280px;
            margin: 0 auto;
            select {
                width: 100%;
                height: 40px;
                padding: 0 7px;
                border: 1px solid @black;
                border-radius: @radius;
                line-height: 40px;
                font-size: 16px;
                font-family: proxima nova, sans-serif;
                color: @black;
                background: #fff;
                cursor: pointer;
            }
        }
        @media (min-width: 1250px) {
            display: flex;
            align-items: center;
            .form__label,
            .form__widget {
                display: block;
                width: 280px;
            }
            .form__label {
                width: 130px;
                padding-right: 10px;
                text-align: right;
            }
            .select2 {
                width: 280px;
            }
            &.js-radio-row .form__widget {
                justify-content: flex-start;
                padding-bottom: 8px;
                label:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
    button[type="submit"] {
        margin-top: 30px;
    }
    @media (min-width: 1250px) {
        width: 410px;
        margin: 0 auto;
    }
}

.order-box {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 12px;
    h2 {
        margin-bottom: 10px;
        font-size: 26px;
        font-weight: 700;
    }
    &__number {
        display: none;
    }
    &__prompt {
        margin-bottom: 15px;
        font-size: 12px;
        line-height: 1.4;
    }
    ul {
        list-style-type: none;
        li {
            margin-bottom: 5px;
            a {
                text-decoration: none;
                font-size: 16px;
                font-weight: 700;
                color: @black;
                &:not([href]) {
                    opacity: .5;
                    cursor: not-allowed;
                }
            }
            &.is-active {
                a {
                    color: @col-sec;
                }
            }
        }
    }
    &__textbox {
        padding: 15px;
        border-radius: @radius;
        margin-top: 20px;
        line-height: 1.4;
        font-style: italic;
        font-size: 13px;
        background-color: #eee;
        a {
            color: @black;
        }
    }
    @media (min-width: 1250px) {
        position: absolute;
        top: 100px;
        left: -300px;
        width: 300px;
        padding: 0;
        border: 0;
        margin: 0;
        h2 {
            margin-bottom: 5px;
        }
        &__number {
            display: block;
            margin-bottom: 20px;
            font-size: 13px;
        }
        &__prompt {
            margin-bottom: 20px;
        }
    }
    @media (min-width: 1450px) {
        position: fixed;
        top: 60px;
        left: 120px;
    }
}

input[type="text"],
input[type="email"] {
    height: 40px;
    border-radius: @radius;
    border: 1px solid @black;
    line-height: 40px;
    font-size: 16px;
    font-family: proxima nova, sans-serif;
    color: @black;
    &.not-filled {
        border-color: #e70505;
        box-shadow: 0 0 1px 1px #e70505;
    }
}

.page-title {
    margin-top: 0;
    line-height: 1.2;
    text-align: center;
    font-size: 26px;
}

.help {
    width: 100%;
    margin: 30px 0 40px 0;
    h2 {
        margin-bottom: 20px;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        &::before {
            display: inline-block;
            margin-right: 5px;
            content: "*";
            color: @col-sec;
        }
    }
    &__screenshot {
        width: 275px;
        margin: 0 auto 20px auto;
        img {
            margin-bottom: 5px;
        }
        p {
            line-height: 1.4em;
            font-size: 14px;
            font-style: italic;
            color: #666;
            em {
                font-weight: 700;
            }
        }
    }
    h3 {
        width: 275px;
        margin: 20px auto 15px auto;
        font-weight: 700;
        font-size: 16px;
        color: #666;
    }
    &__mac {
        width: 275px;
        margin: 0 auto;
        line-height: 1.4;
        font-size: 15px;
        color: #666;
    }
    @media (min-width: 768px) {
        &__screenshots {
            display: flex;
            justify-content: space-between;
        }
        &__screenshot {
            margin: 0 0 20px 0;
        }
        h3 {
            width: 100%;
        }
        &__mac {
            width: 100%;
        }
    }
    @media (min-width: 1200px) {
        margin-top: 100px;
    }
    @media (min-width: 1600px) {
        max-width: 570px;
        margin: 100px auto 0 auto;
    }
}

.download {
    .page-title {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 25px;
        line-height: 1.4;
        text-align: center;
        font-style: italic;
        color: #999;
    }
    .btn {
        margin-bottom: 50px;
    }
    .js-copy-link {
        display: table;
        padding: 0;
        border: 0;
        margin: 0 auto 10px auto;
        font-family: proxima nova, sans-serif;
        font-size: 14px;
        background-color: transparent;
        color: #666;
        cursor: pointer;
        span {
            text-decoration: underline;
        }
        .js-copy-state {
            display: none;
            margin-left: 5px;
            text-decoration: none;
            font-weight: 700;
            color: @col-prim;
        }
        &.is-copied {
            .js-copy-state {
                display: inline;
            }
        }
        &:hover,
        &:focus {
            color: @col-sec;
        }
    }
    input {
        width: 100%;
        padding: 0 11px;
        text-align: center;
        border-color: @black;
    }
    .js-download-another {
        display: table;
        padding: 0;
        border: 0;
        margin: 30px auto 10px auto;
        text-decoration: underline;
        font-family: proxima nova, sans-serif;
        font-size: 14px;
        background-color: transparent;
        &:hover,
        &:focus {
            cursor: pointer;
            color: @col-sec;
        }
    }
}

.login-warning.active {
    position: static;
    width: 100%;
    max-width: 400px;
    padding: 10px 15px;
    border-radius: @radius;
    background-color: #fde3e3;
}

.no-links-text {
    max-width: 500px;
    margin: 0 auto;
    line-height: 1.6;
    text-align: center;
    color: #666;
}